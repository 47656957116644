import React from "react"
import "./latestPost.scss"
import Post from "../Post/Post"
import { graphql, useStaticQuery } from "gatsby"
function LatestPost(props) {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
        edges {
          node {
            id
            uri
            title
            date(formatString: "MMMM DD, YYYY")
            excerpt
            featuredImage {
              node {
                uri
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allWpPost.edges
  return (
    <div className="latest-post">
      <div className="container lastest-posts-container">
        <p className="title">{props.latestPost.title}</p>
        <div className="latest-posts-wrapper">
          {posts?.map(({ node }, index) => {
            return <Post key={index} post={node} />
          })}
        </div>
      </div>
    </div>
  )
}

export default LatestPost
